@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

.home {
  margin: 0;
}

.home header {
  padding: 20px 0;
  margin: 0;
  text-align: center;
  font-family: 'Comfortaa', cursive;
  color: rgb(58, 121, 62);
  backdrop-filter: blur(10px);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.home header h1 {
  margin: 0;
}

/* ===== V LOADING ANIMATION V ===== */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(58, 121, 62);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.loading {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding-top: 10%;

  opacity: 1;
  transition: opacity 0.5s ease-out;
}

/* ===== ^ LOADING ANIMATION ^ ===== */

.track-container {
  position: relative;
  min-height: 100vh;
}

.track-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0 20px;
  flex-wrap: wrap;
  justify-content: space-around;

  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.loaded .loading {
  opacity: 0;
}

.loaded .track-list {
  opacity: 1;
}

.track {
  cursor: pointer;
  background: none;
  border: none;
  height: fit-content;
  width: fit-content;
  transition: 400ms ease-out;
  width: 25vw;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .track {
    width: 15vw;
  }
}

.track:hover {
  transform: translateY(-0.521vw);
}

.track > a {
  flex-grow: 1;
}

.track a {
  text-decoration: none;
}

.track p {
  margin: 5px 0;
}

.album-img {
  width: 100%;
  border-radius: 1.042vw;
  box-shadow: 0px 5px 10px lightslategray;
  pointer-events: none;
}

.artists-names, .track-name {
  color: black;
  text-align: start;
  overflow-wrap: break-word;
}

.artists-names {
  font-style: italic;
  font-size: small;
  color: rgb(58, 121, 62);
}

.artist-link {
  color: rgb(58, 121, 62);
  text-decoration: none;
  font-style: normal;
}

.artist-link:hover {
  text-decoration: underline;
}

/* ===== TRACK PAGE ===== */

.track-page, .artist-page {
  margin: 1%;
  padding-top: 1.352vw;
  justify-content: center;
  text-align: center;
}

.track-page-infos, .artist-page-infos {
  width: 20vw;
  margin: auto;
}

.homepage-btn {
  background-color: rgb(58, 121, 62);
  padding: 1vw;
  border-radius: 1.042vw;
  transition: 300ms ease-out;
  margin: 2.604vw auto;
  width: fit-content;
  border: 0;
  cursor: pointer;
}

.homepage-btn span {
  color: white;
  text-decoration: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.homepage-btn:hover {
  filter: brightness(150%);
}

.spotify-link {
  color: rgb(58, 121, 62);
  text-decoration: none;
}

.spotify-link:hover {
  text-decoration: underline;
}

.artist-genre {
  background-color: rgb(58, 121, 62);
  color: lightgray;
  border-radius: 25px;
  padding: 5px 10px 5px 2.5px;
  width: fit-content;
}

.genre-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  padding: 0;
  margin: 0 0 20px 0;
}

.genre-container li {
  list-style-type: none;
}

.genre-container li span {
  background-color: rgb(98, 202, 105);
  padding: 2.5px 7px;
  border-radius: 50%;
  color: black;
  margin-right: 3px;
}